import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cibAddthis,
  cibBehance,
  cibCcAmex,
  cibCcMastercard,
  cibCcVisa,
  cibDribbble,
  cibFacebook,
  cibFlickr,
  cibGithub,
  cibGooglePay,
  cibInstagram,
  cibLinkedin,
  cibPaypal,
  cibPinterest,
  cibReddit,
  cibSkype,
  cibStackoverflow,
  cibStripe,
  cibTumblr,
  cibTwitter,
  cibVimeo,
  cibVk,
  cibXing,
  cibYahoo,
  cibYoutube,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilApps,
  cilArrowCircleRight,
  cilArrowRight,
  cilArrowThickRight,
  cilArrowThickToRight,
  cilArrowTop,
  cilAsterisk,
  cilAvTimer,
  cilBan,
  cilBarcode,
  cilBasket,
  cilBed,
  cilBell,
  cilBike,
  cilBold,
  cilBolt,
  cilBookmark,
  cilBorderAll,
  cilBriefcase,
  cilBullhorn,
  cilCalculator,
  cilCalendar,
  cilCarAlt,
  cilCenterFocus,
  cilChartPie,
  cilCheck,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCloudDownload,
  cilCloudUpload,
  cilCode,
  cilCoffee,
  cilCommentSquare,
  cilContact,
  cilControl,
  cilCopy,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilEyedropper,
  cilFaceDead,
  cilFile,
  cilFilter,
  cilFire,
  cilFolder,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGraph,
  cilGrid,
  cilHeadphones,
  cilHome,
  cilHttps,
  cilImage,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInfo,
  cilInput,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLibraryAdd,
  cilLifeRing,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilLoop,
  cilLoopCircular,
  cilLowVision,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperPlane,
  cilPaperclip,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPin,
  cilPlaylistAdd,
  cilPlus,
  cilPrint,
  cilPuzzle,
  cilQrCode,
  cilRestaurant,
  cilSave,
  cilScrubber,
  cilSend,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilShortText,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilThumbUp,
  cilTouchApp,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilWallet,
  cilWarning,
  cilX,
  cilXCircle,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cibAddthis,
    cibBehance,
    cibCcAmex,
    cibCcMastercard,
    cibCcVisa,
    cibDribbble,
    cibFacebook,
    cibFlickr,
    cibGithub,
    cibGooglePay,
    cibInstagram,
    cibLinkedin,
    cibPaypal,
    cibPinterest,
    cibReddit,
    cibSkype,
    cibStackoverflow,
    cibStripe,
    cibTumblr,
    cibTwitter,
    cibVimeo,
    cibVk,
    cibXing,
    cibYahoo,
    cibYoutube,
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifUs,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilApps,
    cilArrowCircleRight,
    cilArrowRight,
    cilArrowThickRight,
    cilArrowThickToRight,
    cilArrowTop,
    cilAsterisk,
    cilAvTimer,
    cilBan,
    cilBarcode,
    cilBasket,
    cilBed,
    cilBell,
    cilBike,
    cilBold,
    cilBolt,
    cilBookmark,
    cilBorderAll,
    cilBriefcase,
    cilBullhorn,
    cilCalculator,
    cilCalendar,
    cilCarAlt,
    cilCenterFocus,
    cilChartPie,
    cilCheck,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCloudDownload,
    cilCloudUpload,
    cilCode,
    cilCoffee,
    cilCommentSquare,
    cilContact,
    cilControl,
    cilCopy,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDollar,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilEyedropper,
    cilFaceDead,
    cilFile,
    cilFilter,
    cilFire,
    cilFolder,
    cilFullscreen,
    cilFullscreenExit,
    cilGlobeAlt,
    cilGraph,
    cilGrid,
    cilHeadphones,
    cilHome,
    cilHttps,
    cilImage,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInfo,
    cilInput,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLibraryAdd,
    cilLifeRing,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilLoop,
    cilLoopCircular,
    cilLowVision,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperPlane,
    cilPaperclip,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPin,
    cilPlaylistAdd,
    cilPlus,
    cilPrint,
    cilPuzzle,
    cilQrCode,
    cilRestaurant,
    cilSave,
    cilScrubber,
    cilSend,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilShortText,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilThumbUp,
    cilTouchApp,
    cilTrash,
    cilTruck,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilWallet,
    cilWarning,
    cilX,
    cilXCircle,
  }
);
