var images = [];
var immaginipopolate = false;
var decodeSpReturn = [];
var decodeSpReturnpopolate = false;

export function setImmagini(newimages) {
  images = Object.assign([], newimages);
}
export function getImmagini() {
  return images;
}
export function setImmaginiPopolate() {
  immaginipopolate = true;
}
export function getImmaginiPopolate() {
  return immaginipopolate;
}
export function getImmagineDaID(ID) {
  for (var i in images) {
    if (images[i].ID === ID) {
      return images[i].IMMAGINE;
    }
  }
}

export function setDecodeSpReturn(newdecodeSpReturn) {
  decodeSpReturn = Object.assign([], newdecodeSpReturn);
}
export function setDecodeSpReturnPopolate() {
  decodeSpReturnpopolate = true;
}
export function getDecodeSpReturn() {
  return decodeSpReturn;
}
export function getDecodeSpReturnPopolate() {
  return decodeSpReturnpopolate;
}
export function getDecodeSpReturnDaID(ID) {
  for (var i in decodeSpReturn) {
    if (decodeSpReturn[i].ID === ID) {
      return decodeSpReturn[i];
    }
  }
}
